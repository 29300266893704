<template>
  <span
    class="registryTypeName"
    :title="registryTypeName"
    v-text="registryTypeText"/>
</template>

<script>
  import {mapActions} from 'vuex'

  export default {
    name: 'RegistryTypeName',

    props: {
      value: {
        type: [Number, String],
        default: undefined
      },
      short: Boolean,
      cutTlds: Boolean,
      maxTlds: {
        type: Number,
        default: 2
      }
    },

    data () {
      return {
        registryTypeName: '',
        registryTypeNameShort: ''
      }
    },

    computed: {
      registryTypeText () {
        return this.short
          ? this.value
          : this.cutTlds
            ? this.registryTypeNameShort
            : this.registryTypeName
      }
    },

    watch: {
      value: {
        handler (newValue) {
          this.populateRegistryTypeName (newValue)
          if (this.cutTlds) {
            this.populateRegistryTypeNameShort (newValue)
          }
        },
        immediate: true
      }

    },

    methods: {
      ...mapActions ({
        getRegistryTypeName: 'cache/getRegistryTypeName'
      }),

      async populateRegistryTypeName (registryType) {
        this.registryTypeName = await this.getRegistryTypeName ({id: registryType})
      },

      async populateRegistryTypeNameShort (registryType) {
        this.registryTypeNameShort = await this.getRegistryTypeName ({id: registryType, cutTlds: this.maxTlds})
      }
    }
  }
</script>

<style scoped>
.registryTypeName {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: bottom;
  max-width: 100%;
}
</style>
