<messages>["../AuditReports"]</messages>

<template>
  <report-wrapper
    :headers="headers"
    :sort-function="sort"
    client
    endpoint="account-reports/client-registry">
    <template #default="{reportEntries}">
      <tr v-for="item in reportEntries" :key="item.clientId+item.month+item.registryHandle+item.originalCurrency">
        <td>{{ item.month }}</td>
        <td class="text-right">
          {{ item.clientId }}
        </td>
        <td>{{ item.registryHandle }}</td>
        <td>{{ item.bookedCurrency }}</td>
        <td>{{ item.originalCurrency }}</td>
        <td class="text-right">
          {{ item.chargeCount }}
        </td>
        <td class="text-right">
          {{ item.refundCount }}
        </td>
        <td class="text-right">
          {{ formatDecimal (item.clientChargesNoVAT) }}
        </td>
        <td class="text-right">
          {{ formatDecimal (item.clientRefundsNoVAT) }}
        </td>
        <td class="text-right">
          {{ formatDecimal (item.clientChargesVAT) }}
        </td>
        <td class="text-right">
          {{ formatDecimal (item.clientRefundsVAT) }}
        </td>
        <td class="text-right">
          {{ formatDecimal (item.clientTotal) }}
        </td>
        <td>{{ item.clientName }}</td>
        <td>{{ item.clientVATId }}</td>
        <td>{{ item.clientVATStatus }}</td>
        <td>{{ item.registryVATStatus }}</td>
        <td>{{ item.registryChargesNoVAT }}</td>
        <td>{{ item.registryRefundsNoVAT }}</td>
        <td>{{ item.registryChargesVAT }}</td>
        <td>{{ item.registryRefundsVAT }}</td>
        <td>{{ item.registryTotal }}</td>
        <td>
          <registry-type-name
            v-if="item.registryHandle"
            :value="item.registryHandle"
            cut-tlds/>
        </td>
        <td>{{ item.registryVATId }}</td>
      </tr>
    </template>
  </report-wrapper>
</template>

<script>
  import ReportWrapper from './ReportWrapper'

  import RegistryTypeName from '@/app/core/components/RegistryTypeName'

  export default {
    components: {
      ReportWrapper,
      RegistryTypeName
    },

    data () {
      return {
        clientId: null,
        startDate: null,
        endDate: null,
        isLoading: false,
        lastUsedParams: null
      }
    },

    computed: {

      headers () {
        return [
          this.$t ('headers.month'),
          this.$t ('headers.clientId'),
          this.$t ('headers.registryHandle'),
          this.$t ('headers.bookedCurrency'),
          this.$t ('headers.originalCurrency'),
          this.$t ('headers.chargesCount'),
          this.$t ('headers.refundCount'),
          this.$t ('headers.clientChargesNoVAT'),
          this.$t ('headers.clientRefundsNoVAT'),
          this.$t ('headers.clientChargesVAT'),
          this.$t ('headers.clientRefundsVAT'),
          this.$t ('headers.clientTotal'),
          this.$t ('headers.clientName'),
          this.$t ('headers.clientVATId'),
          this.$t ('headers.clientVATStatus'),
          this.$t ('headers.registryVATStatus'),
          this.$t ('headers.registryChargesNoVAT'),
          this.$t ('headers.registryRefundsNoVAT'),
          this.$t ('headers.registryChargesVAT'),
          this.$t ('headers.registryRefundsVAT'),
          this.$t ('headers.registryTotal'),
          this.$t ('headers.registryName'),
          this.$t ('headers.registryVATId')
        ]
      }
    },

    methods: {
      formatDecimal (v) {
        return (Math.round (v * 10000) / 10000).toFixed (4)
      },

      sort (a, b) {
        const [yA, mA] = a.month.split ('-').map (x => Number.parseInt (x))
        const [yB, mB] = b.month.split ('-').map (x => Number.parseInt (x))

        if (yA > yB) {
          return 1
        } else if (yA < yB) {
          return -1
        } else {
          if (mA > mB) {
            return 1
          } else if (mA < mB) {
            return -1
          } else {
            if (a.clientId > b.clientId) {
              return 1
            } else if (a.clientId < b.clientId) {
              return -1
            } else {
              return a.registryHandle.localeCompare (b.registryHandle)
            }
          }
        }
      }
    }
  }
</script>
