<template>
  <v-menu
    v-model="isMenuVisible"
    :disabled="disabled"
    :close-on-content-click="false"
    transition="scale-transition">
    <template #activator="{ on }">
      <v-text-field
        :value="format (value)"
        :disabled="disabled"
        :class="{required}"
        :label="label"
        :error-messages="errorMessages"
        append-icon="event"
        readonly
        @click:append="on.click"
        v-on="on"
        @blur="$emit('blur')"/>
    </template>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="valueAsString"
              :value="format (value)"
              :disabled="disabled"
              :class="{required}"
              :label="label"
              :error-messages="errorMessages"
              append-icon="event"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-date-picker
              v-model="date"
              flat
              actions
              first-day-of-week="1"
              :locale="$i18n.locale"/>
          </v-col>
          <v-col>
            <v-time-picker
              v-model="time"
              color="primary"
              header-color="primary"
              format="24hr"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn v-if="!required" text @click="clear">
          {{ $t ('general.button.delete') }}
        </v-btn>
        <v-btn v-if="now" text @click="useNow">
          {{ $t ('general.button.now') }}
        </v-btn>
        <v-btn color="primary" @click="onOk">
          {{ $t ('general.button.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>

  export default {
    name: 'DateTimePickerField',

    props: {
      label: {
        type: String,
        default: undefined
      },

      required: {
        type: Boolean,
        default: false
      },

      disabled: {
        type: Boolean,
        default: false
      },

      value: {
        type: Number,
        default: undefined
      },

      errorMessages: {
        type: Array,
        default: undefined
      },

      now: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        isMenuVisible: false,
        date: null,
        time: '00:00',
        valueAsString: ''
      }
    },

    computed: {
      dateTime: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit ('input', newValue)
        }
      }
    },

    watch: {
      date (newVal) {
        if (newVal && this.time) {
          this.dateTime = Date.parse (`${newVal}T${this.time}+00:00`)
          this.valueAsString = this.format ()
        }
      },

      time (newVal) {
        if (this.date && newVal) {
          this.dateTime = Date.parse (`${this.date}T${newVal}+00:00`)
          this.valueAsString = this.format ()
        }
      },

      value: {
        handler (newVal) {
          if (newVal) {
            this.date = this.formatDateShort (newVal, true)
            this.time = this.formatTime (newVal, true)
            this.valueAsString = this.format ()
          } else {
            this.date = null
            this.time = '00:00'
          }
        },
        immediate: true
      },

      valueAsString (newValue) {
        const formatTester = /(\d\d\d\d)-(\d\d)-(\d\d) (\d\d):(\d\d)/

        if (!formatTester.test (newValue)) {
          return
        }

        const [date, time] = newValue.split (' ')
        const datetime = Date.parse (`${date}T${time}+00:00`)

        if (datetime) {
          this.dateTime = datetime
        }
      }
    },

    methods: {
      onOk () {
        this.isMenuVisible = false
      },

      useNow () {
        this.dateTime = new Date ().getTime ()
        this.onOk ()
      },

      clear () {
        this.dateTime = null
        this.onOk ()
      },

      format (v) {
        if (this.value) {
          return this.formatDate (this.value, true)
        } else {
          return ''
        }
      }
    }
  }
</script>
