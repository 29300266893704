<messages>["../AuditReports"]</messages>

<template>
  <v-card
    flat
    tile>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <date-time-picker-field
                  v-model="startDate"
                  :error-messages="validationErrors (
                    'startDate', {
                      required: 'general.required'
                    })"
                  :label="$t('label.startDate')"/>
              </v-col>
              <v-col cols="12" sm="6">
                <date-time-picker-field
                  v-model="endDate"
                  :error-messages="validationErrors (
                    'endDate', {
                      required: 'general.required'
                    })"
                  :label="$t('label.endDate')"/>
              </v-col>
              <v-col>
                <v-row>
                  <v-col
                    v-for="btn in quickfilter"
                    :key="btn.label"
                    cols="auto">
                    <v-btn
                      v-t="btn.label"
                      rounded small
                      @click="btn.method"/>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="client" cols="12">
                <client-select
                  v-model="clientIds"
                  multiple
                  for-accounting
                  for-view
                  nullable/>
              </v-col>
              <v-col v-if="registry" cols="12">
                <registry-select
                  v-model="registryId"
                  clearable
                  spellcheck="false"
                  :label="$t ('label.registry')"/>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <template v-if="!isLoading && reportEntries">
              <v-btn
                text
                :href="csvDownloadUrl">
                {{ $t ('general.button.downloadAsCsv') }}
                <v-icon>get_app</v-icon>
              </v-btn>
            </template>
            <v-spacer/>
            <v-btn color="primary" @click="submit">
              {{ $t('general.button.submit') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-card-text>
      <v-simple-table v-if="!isLoading && reportEntries" dense>
        <thead>
          <tr>
            <th v-for="header in headers" :key="header">
              {{ header }}
            </th>
          </tr>
        </thead>

        <tbody>
          <slot v-if="reportEntries.length" :reportEntries="reportEntries"/>
          <tr v-else>
            <td class="text-center" :colspan="headers.length">
              {{ $t ('label.empty') }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-progress-linear v-else-if="isLoading" indeterminate/>
    </v-card-text>
  </v-card>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import {required} from 'vuelidate/lib/validators'

  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import RegistrySelect from '@/app/core/components/RegistrySelect'
  import ClientSelect from '@/app/core/components/ClientSelect'
  import DateTimePickerField from '@/app/core/components/DateTimePickerField/DateTimePickerField'

  function getDate (rel) {
    const date = rel ? new Date () : new Date ()
    date.setUTCHours (0, 0, 0, 0)
    return date
  }

  export function lastYear () {
    const lastYearStart = getDate ()
    lastYearStart.setUTCDate (1)
    lastYearStart.setUTCMonth (0)
    lastYearStart.setUTCFullYear (lastYearStart.getUTCFullYear () - 1)

    const currentYearStart = getDate ()

    currentYearStart.setUTCDate (1)
    currentYearStart.setUTCMonth (0)

    return {start: lastYearStart, end: currentYearStart}
  }

  export function currentMonth () {
    const currentMonthStart = getDate ()

    currentMonthStart.setUTCDate (1)

    return {start: currentMonthStart, end: new Date ()}
  }

  export function currentYear () {
    const currentYearStart = getDate ()

    currentYearStart.setUTCDate (1)
    currentYearStart.setUTCMonth (0)

    return {start: currentYearStart, end: new Date ()}
  }

  export function lastMonth () {
    const lastMonthStart = getDate ()
    lastMonthStart.setUTCDate (1)
    lastMonthStart.setUTCMonth (lastMonthStart.getUTCMonth () - 1)

    const currentMonthStart = getDate ()

    currentMonthStart.setUTCDate (1)

    return {start: lastMonthStart, end: currentMonthStart}
  }

  export default {
    components: {
      ClientSelect,
      DateTimePickerField,
      RegistrySelect
    },

    mixins: [validationMixins],

    validations: {
      endDate: {required},
      startDate: {required}
    },

    props: {
      endpoint: {
        type: String,
        default: ''
      },
      headers: {
        type: Array,
        default: undefined
      },
      client: {
        type: Boolean,
        default: false
      },
      registry: {
        type: Boolean,
        default: false
      },
      sortFunction: {
        type: Function,
        default: undefined
      }
    },

    data () {
      return {
        clientIds: [],
        registryId: null,
        startDate: null,
        endDate: null,
        reportEntries: null,
        isLoading: false,
        lastUsedParams: null,
        quickfilter: [
          {
            label: 'quickfilter.currentMonth',
            method: this.dateSetter (currentMonth)
          },
          {
            label: 'quickfilter.lastMonth',
            method: this.dateSetter (lastMonth)
          },
          {
            label: 'quickfilter.currentYear',
            method: this.dateSetter (currentYear)
          },
          {
            label: 'quickfilter.lastYear',
            method: this.dateSetter (lastYear)
          }
        ]

      }
    },

    computed: {
      ...mapGetters ({
        operationLink: 'request/operationLink'
      }),

      csvDownloadUrl () {
        return this.operationLink ({
          op: this.endpoint,
          params: this.lastUsedParams
        })
      }
    },

    created () {
      let desiredQuery

      try {
        desiredQuery =
          this.generateFilter (JSON.parse (this.$route.query[this.endpoint] || null))
      } catch (ex) {
        this.setQueryFilter ()
        return
      }

      if (this.validateFilter (desiredQuery)) {
        this.startDate = desiredQuery.startDate
        this.endDate = desiredQuery.endDate
        this.clientIds = desiredQuery.clientIds
        this.registryId = desiredQuery.registryId
        this.submit ()
      } else {
        this.setQueryFilter ()
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      generateFilter (f) {
        return {
          startDate: Number.parseInt (f?.startDate),
          endDate: Number.parseInt (f?.endDate),
          clientIds: f?.clientIds,
          registryId: f?.registryId
        }
      },

      validateFilter (filter) {
        const {startDate, endDate} = filter

        if (Number.isNaN (startDate)) {
          return false
        }

        if (Number.isNaN (endDate)) {
          return false
        }

        return !(!startDate || !endDate)
      },

      submit () {
        if (!this.$v.$invalid) {
          this.lastUsedParams = {
            ...this.client
              ? {
                clientIds: this.clientIds
              }
              : {},
            ...this.registry
              ? {
                registryId: this.registryId
              }
              : {},

            start: this.startDate,
            end: this.endDate
          }

          this.setQueryFilter ()

          this.isLoading = true

          this.fetchData ({
            op: this.endpoint,
            params: this.lastUsedParams,
            cb: data => {
              this.reportEntries = data.report

              if (this.sortFunction) {
                this.reportEntries = [...this.reportEntries].sort (this.sortFunction)
              }
            },
            cbFinal: () => {
              this.isLoading = false
            }
          })
        } else {
          this.$v.$touch ()
        }
      },

      setQueryFilter () {
        this.$router.replace ({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            [this.endpoint]: JSON.stringify ({
              startDate: this.startDate,
              endDate: this.endDate,
              ...this.client
                ? {
                  clientIds: this.clientIds
                }
                : {},
              ...this.registry
                ? {
                  registryId: this.registryId
                }
                : {}
            })
          }
        })
      },

      dateSetter (dateGetter) {
        return () => {
          const {start, end} = dateGetter ()
          this.startDate = start.getTime ()
          this.endDate = end.getTime ()
        }
      }
    }
  }
</script>
