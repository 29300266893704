<messages>["./AuditReports"]</messages>

<template>
  <base-layout mw2>
    <v-col cols="12">
      <v-card>
        <v-card-title primary-title>
          <v-row>
            <v-col>
              <div
                v-t="'title.main'"
                class="text-h5"/>
              <div
                v-t="'title.mainSub'"
                class="cgwng-subheading"/>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-tabs
            v-model="tab">
            <v-tabs-slider/>

            <v-tab
              v-for="t in tabs"
              :key="t"
              :href="`#${t}`">
              {{ $t(`label.tabs.${t}`) }}
            </v-tab>

            <v-tab-item
              :value="CLIENT_REGISTRY">
              <turnover/>
            </v-tab-item>

            <v-tab-item
              :value="CLIENT_BALANCE">
              <v-card
                flat
                tile>
                <client-balance-report/>
              </v-card>
            </v-tab-item>

            <v-tab-item
              :value="PROVIDER_BALANCE">
              <v-card
                flat
                tile>
                <provider-balance-report/>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import BaseLayout from '@/app/core/components/BaseLayout'

  import Turnover from './components/Turnover'
  import ClientBalanceReport from './components/ClientBalanceReport'
  import ProviderBalanceReport from './components/ProviderBalanceReport'

  const CLIENT_REGISTRY = 'client-registry'
  const CLIENT_BALANCE = 'client-balance'
  const PROVIDER_BALANCE = 'provider-balance'

  export default {
    components: {
      BaseLayout,
      Turnover,
      ClientBalanceReport,
      ProviderBalanceReport
    },

    data () {
      return {
        tab: CLIENT_REGISTRY,
        CLIENT_REGISTRY,
        CLIENT_BALANCE,
        PROVIDER_BALANCE
      }
    },

    computed: {
      tabs () {
        return [
          CLIENT_REGISTRY,
          CLIENT_BALANCE,
          PROVIDER_BALANCE
        ]
      }
    },

    watch: {
      tab: {
        handler (newVal) {
          this.$router.push ({
            name: this.$route.name,
            query: {...this.$route.query, report: newVal}
          })
        }
      },

      '$route.query.report': {
        handler (newVal) {
          if (this.tabs.includes (newVal)) {
            this.tab = newVal
          }
        }
      }
    },

    created () {
      const desiredReport = this.$route.query.report

      if (this.tabs.includes (desiredReport)) {
        this.tab = desiredReport
      } else {
        this.$router.replace ({
          name: this.$route.name,
          query: {...this.$route.query, report: this.tab}
        })
      }
    }
  }
</script>
