<messages>["../AuditReports"]</messages>

<template>
  <report-wrapper
    :headers="headers"
    client
    :sort-function="sort"
    endpoint="account-reports/client-balance">
    <template #default="{reportEntries}">
      <tr v-for="item in reportEntries" :key="item.objectId+item.currency">
        <td>{{ item.objectId }} </td>
        <td>{{ item.active }} </td>
        <td>{{ item.objectName }} </td>
        <td>{{ item.currency }} </td>
        <td>{{ item.vatId }} </td>
        <td>{{ item.vatStatus }} </td>
        <td class="text-right">
          {{ item.startBalance ? formatDecimal (item.startBalance) : EmptyMark }}
        </td>
        <td class="text-right">
          {{ formatDecimal (item.charges) }}
        </td>
        <td class="text-right">
          {{ formatDecimal (item.chargesVAT) }}
        </td>
        <td class="text-right">
          {{ item.chargeCount }}
        </td>
        <td class="text-right">
          {{ formatDecimal (item.refunds) }}
        </td>
        <td class="text-right">
          {{ formatDecimal (item.refundsVAT) }}
        </td>
        <td class="text-right">
          {{ item.refundCount }}
        </td>
        <td class="text-right">
          {{ formatDecimal (item.deposits) }}
        </td>
        <td class="text-right">
          {{ formatDecimal (item.withdrawals) }}
        </td>
        <td class="text-right">
          {{ formatDecimal (item.membershipFees) }}
        </td>
        <td class="text-right">
          {{ formatDecimal (item.membershipFeesVAT) }}
        </td>
        <td class="text-right">
          {{ formatDecimal (item.endBalance) }}
        </td>
      </tr>
    </template>
  </report-wrapper>
</template>

<script>
  import {EmptyMark} from '@/app/utils/string'

  import ReportWrapper from './ReportWrapper'

  export default {
    components: {
      ReportWrapper
    },

    data () {
      return {
        EmptyMark
      }
    },

    computed: {
      headers () {
        return [
          this.$t ('headers.clientId'),
          this.$t ('headers.active'),
          this.$t ('headers.clientName'),
          this.$t ('headers.currency'),
          this.$t ('headers.vatId'),
          this.$t ('headers.vatStatus'),
          this.$t ('headers.startBalance'),
          this.$t ('headers.charges'),
          this.$t ('headers.chargesVAT'),
          this.$t ('headers.chargesCount'),
          this.$t ('headers.refunds'),
          this.$t ('headers.refundsVAT'),
          this.$t ('headers.refundCount'),
          this.$t ('headers.deposits'),
          this.$t ('headers.withdrawals'),
          this.$t ('headers.membershipFees'),
          this.$t ('headers.membershipFeesVAT'),
          this.$t ('headers.endBalance')
        ]
      }
    },

    methods: {
      formatDecimal (v) {
        let value = 0
        if (v !== undefined && v !== null) value = v
        return (Math.round (value * 10000) / 10000).toFixed (4)
      },
      sort (a, b) {
        const comp = a.objectId - b.objectId
        return comp === 0 ? a.currency.localeCompare (b.currency) : comp
      }
    }
  }
</script>
